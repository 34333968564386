.button--primary {
  display: block;
  width: max-content;
  background-color: transparent;
  padding: 1.25rem 2rem;
  margin-top: 3.5rem;
  border: 2px solid var(--clr-secondary-300);
  border-radius: 2rem;
  transition: all 0.5s ease-in-out;

  // font styles
  text-transform: uppercase;
  font-weight: 500;
  color: var(--clr-secondary-400);
  font-size: 0.83rem;
  text-align: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.146);
    color: var(--clr-primary-400);
  }
}
