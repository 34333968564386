.skill {
  width: 5rem;
  opacity: 0.4;
  transition: all 0.5s ease-in-out;

  img {
    height: 6rem;
    filter: brightness(0) saturate(100%) invert(20%) sepia(0%) saturate(2521%)
      hue-rotate(159deg) brightness(98%) contrast(86%);
    margin-bottom: 1rem;
    margin-inline: auto;
  }
  figcaption {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--clr-secondary-400);
  }

  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
}
