@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body {
  background-color: var(--clr-primary-300);
  background-size: 100% 8rem;
  background-repeat: no-repeat;
  transition: background 0.5s ease-in-out;
  font-family: "Inter", sans-serif;
}

.container {
  width: 87%;
  max-width: 70em;
  margin-inline: auto;
}

p {
  font-size: 1.15rem;
}

p,
span,
a {
  color: var(--clr-secondary-400);
}

h2 {
  margin-bottom: 2.5rem;
}

a:hover:not(a[class="link__img"]),
button:hover {
  transition: opacity 0.5s ease-in-out;
  opacity: 0.5;
  cursor: pointer;
}

// VARIABLES
body {
  --clr-primary-300: white;
  --clr-primary-400: hsla(0, 0%, 96%, 1);
  --clr-secondary-300: hsla(0, 0%, 24%, 0.5);
  --clr-secondary-400: hsla(0, 0%, 24%, 1);
}
