.wave {
  width: 100%;
  height: 4rem;
  display: block;
  transform: rotate(180deg);

  &.flipped {
    transform: rotate(0deg);
  }
}

.wave svg {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.wave .shape-fill {
  fill: var(--clr-primary-400);
}
