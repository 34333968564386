.project {
  background-color: hsla(0, 0%, 96%, 0.75);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
}

.project__img,
.project__title,
.project__description {
  margin-bottom: 1.25rem;
}

.project__description {
  line-height: 1.4em;
  font-size: 1rem;
}

.project__badges {
  display: flex;
  column-gap: 0.75rem;
}

.project__img {
  filter: blur(0.05rem) grayscale(0.95);
  box-shadow: 0px 0px 50px -2rem var(--clr-secondary-400);
  transition: all 0.5s ease-in-out;
  aspect-ratio: 16/10;

  &:hover {
    transform: scale(1.05);
    filter: none;
  }
}

.project__links {
  margin-bottom: 1rem;
  margin-top: auto;
  padding-top: 1rem;

  a {
    margin-right: 1rem;
    padding-bottom: 0.25rem;
    transition: all 0.5s ease-in-out;
    border-bottom: 2px transparent solid;

    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;

    &:hover {
      border-bottom: 2px var(--clr-secondary-400) solid;
    }
  }
}

// badge

.badge {
  border: 1px var(--clr-secondary-300) solid;
  border-radius: 1rem;
  width: 4rem;
  height: 1.25rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  color: var(--clr-secondary-400);
  font-size: 0.65rem;
}

// skeleton

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  opacity: 0.75;
  border-radius: 0.3rem;

  @keyframes skeleton-loading {
    0% {
      background-color: rgb(245, 245, 245);
    }

    100% {
      background-color: rgb(211, 211, 211);
    }
  }
}

.skeleton__img,
.skeleton__title,
.skeleton__links,
.skeleton__description {
  margin-bottom: 1.25rem;
}

.skeleton__img {
  width: 100%;
  aspect-ratio: 16/9;
}

.skeleton__title {
  height: 1.5rem;
  width: 85%;
}

.skeleton__description > div {
  margin-bottom: 0.4rem;
  height: 1.15rem;
}

.skeleton__description > div:last-of-type {
  width: 75%;
}

.skeleton__links {
  margin-top: 1rem;
  width: 65%;
  height: 1rem;
}

.skeleton__badges {
  height: 1rem;
  width: 90%;
}
