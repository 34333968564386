.contact {
  background-color: var(--clr-primary-400);
  position: relative;
  padding: 6rem 0;
}

.contact__text {
  font-size: 1.15rem;
  margin-bottom: 2.5rem;
}

.input {
  margin-bottom: 1.5rem;
  position: relative;
}

.form__input {
  background-color: transparent;
  display: block;
  width: 100%;
  padding: 1rem;
  border: 1px var(--clr-secondary-300) solid;
  border-radius: 0.5rem;

  color: var(--clr-secondary-400);
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}

.form__label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0 0.3rem;
  color: var(--clr-secondary-300);
  transition: all 0.5s ease-in-out;
  cursor: text;
}

@media screen and (min-width: 48em) {
  .contact .container {
    max-width: 55em;
  }
}

// focus state
.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown) ~ .form__label {
  top: -0.6rem;
  background-color: var(--clr-primary-400);
  color: var(--clr-secondary-400);
}

// hover state
.form__input:hover,
.form__input:focus {
  border: 1px var(--clr-secondary-400) solid;
}
