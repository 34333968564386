.footer {
  text-align: center;
  padding: 3rem 0;
  padding-inline: 8vw;
}

.footer__text {
  margin-bottom: 2rem;
  font-size: 1rem;
}

.footer__nav > ul {
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  filter: invert(21%) sepia(0%) saturate(1348%) hue-rotate(226deg)
    brightness(102%) contrast(91%);
}

.footer__nav img {
  width: 2.25rem;
  height: 2.25rem;

  &:hover {
    transform: scale(1.25);
  }
}

// fix for oversize github image lol
.nav__github a img {
  content: url("../../assets/github.ico");
  transform: scale(0.65);
  &:hover {
    transform: scale(0.9);
  }
}
