.about {
  padding: 2rem 0 6rem 0;
  position: relative;
  background-color: var(--clr-primary-400);
}

.about__title {
  margin-bottom: 2rem;
}

.about__para {
  margin-bottom: 2rem;
  font-size: 1.15rem;
}

.about__button {
  margin-inline: auto;
  margin-top: 3.5rem;
}

@media screen and (min-width: 48em) {
  .about {
    padding: 6rem 0;
  }
  .about .container {
    max-width: 55em;
  }
}
