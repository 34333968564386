// RESET
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 100%;
  scroll-behavior: smooth;
}
p {
  line-height: 1.5em;
  margin-bottom: 2em;
}
a,
a:visited,
a:active {
  text-decoration: none;
}
img,
picture {
  max-width: 100%;
  display: block;
  box-sizing: content-box;
}
li {
  list-style: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
