.splash {
  padding-top: 8vh;
  height: 100vh;
  position: relative;
  background: linear-gradient(180deg, #dadada 0%, #ffffff 100%);
  background-size: 100% 12vh;
  background-repeat: no-repeat;
}

.splash__title {
  font-size: clamp(2rem, 4vw, 2.6rem);
  max-width: 50rem;
  margin-bottom: 3rem;
  line-height: 1.3em;

  .emphasis {
    font-weight: 800;
  }
}

.Typewriter {
  font-size: clamp(1.25rem, 4vw, 1.3rem);
}

.scroll-indicator {
  position: absolute;
  animation: bounce 0.5s infinite alternate;

  .scroll__arrow {
    margin-right: 0.25em;
  }

  @keyframes bounce {
    from {
      bottom: 5vh;
    }
    to {
      bottom: 5.5vh;
    }
  }
}

// MEDIA QUERIES

@media screen and (min-width: 48em) {
  .splash {
    padding-top: 6rem;
    padding-bottom: 14rem;
  }
}

@media screen and (min-width: 66em) {
  .splash {
    display: flex;
    align-items: center;
  }
}
