.hamburger {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 18px;
  margin-left: auto;

  > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1rem;
    background-color: var(--clr-secondary-400);
    transition: all 0.5s ease-in-out;
    transform-origin: 27.5px;
  }

  &.visible {
    height: 24px;

    > span:first-child {
      transform: rotate(-45deg);
    }
    > span:nth-child(2) {
      opacity: 0;
      transform: translateX(3rem);
    }
    > span:last-child {
      transform: rotate(45deg);
    }
  }

  @media screen and (min-width: 48em) {
    display: none;
  }
}
